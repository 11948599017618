import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { CDNRoot, unknownImage } from "../../constants/urls";
import { ImageWrapperProps } from "./ImageWrapperProps";

const loadingImg = `${CDNRoot}/static/other/loading.gif`;

const ImageWrapper = ({
  src,
  fallbackSrc,
  width,
  height,
  fullWidth,
  alt,
  autoHeight,
  fit,
  className,
  style,
  hideOnError,
  children,
  wrapperSx,
  reload,
}: ImageWrapperProps) => {
  const [imageSrc, setImageSrc] = useState(src);

  const useFallBackImg = () => {
    setImageSrc(fallbackSrc || (reload ? loadingImg : unknownImage));
  };

  useEffect(() => {
    setImageSrc(src);
  }, [src, reload]);

  useEffect(() => {
    if (reload) {
      let attempts = 0;
      const maxAttempts = 10; // Stop after 10 retries (20 seconds)

      const interval = setInterval(() => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
          setImageSrc(src);
          clearInterval(interval);
        };
        img.onerror = () => {
          attempts++;
          if (attempts >= maxAttempts) {
            useFallBackImg();
            clearInterval(interval);
          }
        };
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [reload, src]);

  const appliedHeight = autoHeight ? "auto" : height || width;
  const appliedWidth = fullWidth ? "100%" : width;

  const imageElementHeight = children ? "100%" : appliedHeight;
  const imageElementWidth = children ? "100%" : appliedWidth;

  const imageElement = (
    <img
      src={imageSrc}
      onError={useFallBackImg}
      alt={alt || ""}
      height={appliedHeight}
      width={appliedWidth}
      style={{
        ...style,
        objectFit: fit || style?.objectFit,
        height: imageElementHeight,
        width: imageElementWidth,
      }}
      className={className}
    />
  );

  return hideOnError && imageSrc === unknownImage ? (
    <></>
  ) : children ? (
    <Stack
      sx={{
        ...wrapperSx,
        position: "relative",
      }}
      alignItems={"center"}
      justifyContent={"center"}
      height={appliedHeight}
      width={appliedWidth}
      maxWidth="100%"
    >
      {children && (
        <Box
          sx={{
            position: "absolute",
            right: -5,
            bottom: -5,
            zIndex: 1,
          }}
        >
          {children}
        </Box>
      )}
      {imageElement}
    </Stack>
  ) : (
    imageElement
  );
};

export default ImageWrapper;
