"use client";

import { ImageWrapper } from "@anthology/shared/src/components";
import { slinkyDemoImageUrl } from "@anthology/shared/src/components/slinky";
import { useCssBreakpoint } from "@anthology/shared/src/hooks";
import {
  Box,
  Button,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { FaChartArea, FaHeart, FaLink, FaPalette } from "react-icons/fa";
import { FaPerson } from "react-icons/fa6";

const features = [
  {
    title: "All-In-One Marketing Hub",
    description:
      "With Slinky, you can consolidate all your streaming, social, and purchase links into one destination. No more sharing multiple links – just one easy access point for fans to discover your music or event.",
    icon: FaLink,
  },
  {
    title: "Effortless Fan Engagement",
    description:
      "Slinky makes it simple for fans to interact with your content on the platforms they prefer. Whether they're on Spotify, Apple Music, YouTube, or another platform, Slinky connects them instantly.",
    icon: FaPerson,
  },
  {
    title: "Pre-Save and Pre-Order Capabilities",
    description:
      "Build excitement for your upcoming releases by allowing fans to pre-save or pre-order directly from your Slinky page. Capture engagement early and ensure your content hits your fans' playlists the moment it's available.",
    icon: FaHeart,
  },
  {
    title: "Boost Your Analytics",
    description:
      "Track clicks, engagement rates, and platform preferences to understand where your fans are most active. Use these insights to optimise your marketing and focus on what's working best.",
    icon: FaChartArea,
  },
  {
    title: "Customisable and Brand-Friendly",
    description:
      "Create a branded experience that aligns with your style and vision. Customize your Slinky page to make it uniquely yours, ensuring a seamless fan experience from first click to final purchase.",
    icon: FaPalette,
  },
];

const LandingPage = () => {
  const isMobileLayout = useCssBreakpoint("sm");
  return (
    <Box sx={{ bgcolor: "background.default", minHeight: "100vh" }}>
      {/* Hero Section */}
      <Box
        sx={{
          background: {
            xs: "linear-gradient(180deg, #1c1d24 0%, #ffffff 100%)",
            md: "linear-gradient(90deg, #1c1d24 0%, #1c1d24 40%, #ffffff 100%)",
          },
          color: "white",
          py: 8,
          pb: 2,
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Container maxWidth="lg">
          <Stack
            sx={{ flexDirection: { xs: "column", md: "row" } }}
            position={"relative"}
          >
            <Stack alignItems={"flex-start"}>
              <Typography
                variant={isMobileLayout ? "h3" : "h2"}
                component="h1"
                sx={{
                  fontWeight: "bold",
                  mb: 2,
                  animation: "fadeIn 1s ease-in",
                }}
              >
                One Link, Endless Possibilities to Build Your Audience
              </Typography>
              <Typography variant="h4" sx={{ mb: 4 }}>
                Simplify your promotion. Amplify your reach.
              </Typography>
              <Typography variant="h5" sx={{ mb: 4 }}>
                Slinky is your all-in-one promotion tool for music, events, and
                products. With a single link, give your fans easy access to
                stream, buy, pre-save, and engage on their preferred platforms.
                Whether you're an artist, label, or content creator, Slinky
                streamlines your marketing and maximises fan engagement.
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                href="https://absolutelabelservices.com"
                sx={{
                  py: 2,
                  px: 4,
                  fontSize: "1.2rem",
                }}
              >
                Learn More
              </Button>
            </Stack>
            <Box
              sx={{ margin: { xs: "-20px -100px", md: "-120px 15px" } }}
              maxWidth={"80%"}
            >
              <ImageWrapper
                style={{
                  width: "auto",
                  height: "80%",
                  maxHeight: "700px",
                }}
                src={slinkyDemoImageUrl}
              />
            </Box>
          </Stack>
        </Container>
      </Box>

      {/* Features Section */}
      <Container maxWidth="md" sx={{ py: isMobileLayout ? 4 : 8 }}>
        <Typography
          variant="h3"
          component="h2"
          sx={{
            my: 6,
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Why Choose Slinky?
        </Typography>
        <List>
          {features.map((feature, index) => (
            <Stack
              key={index}
              sx={{
                mb: 3,
                p: { xs: 1, md: 3 },
                // transition: "transform 0.2s",
                // "&:hover": {
                //   transform: "translateY(-4px)",
                // },
              }}
            >
              <ListItem alignItems="flex-start">
                <ListItemIcon
                  sx={{
                    mt: 1,
                    color: "var(--mui-palette-secondary-main)",
                  }}
                >
                  {feature.icon({
                    size: 30,
                    color: "var(--mui-palette-secondary-main)",
                  })}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="h5" sx={{ mb: 1, fontWeight: "bold" }}>
                      {feature.title}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="bodyLarge" color="text.secondary">
                      {feature.description}
                    </Typography>
                  }
                />
              </ListItem>
            </Stack>
          ))}
        </List>
      </Container>

      {/* Call to Action Section */}
      <Box sx={{ bgcolor: "grey.100", py: 8 }}>
        <Container maxWidth="md">
          <Typography
            variant="h4"
            component="h2"
            sx={{
              mb: 4,
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Ready to Elevate Your Promotions?
          </Typography>
          <Typography
            variant="bodyLarge"
            sx={{
              mb: 4,
              textAlign: "center",
            }}
          >
            Slinky link creation is currently only available to customers of
            Absolute Label Services Ltd. Absolute is a label services company
            offering distribution and marketing services worldwide.
          </Typography>
          <Box sx={{ textAlign: "center" }} mt={6}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              href="https://absolutelabelservices.com"
              sx={{
                py: 2,
                px: 4,
                fontSize: "1.1rem",
                color: "#ffffff",
              }}
            >
              {`Find out more${isMobileLayout ? "" : " about our services"}`}
            </Button>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default LandingPage;
